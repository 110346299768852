import React, { useRef, useState } from "react";
import { Form, Formik } from "formik";
import { Button, FormField, notification } from "@epcnetwork/core-ui-kit";
import { useDidUpdate } from "@better-hooks/lifecycle";
import { useSubmit } from "@hyper-fetch/react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

import { initialValues, validationSchema } from "./suppress-manually.constants";
import { createSuppressions } from "api";
import { DASHBOARD_PAGE, JOBS_LIST_PAGE } from "constants/routes.constants";
import { SuppressManuallyFormData } from "./suppress-manually.types";
import { RootState } from "store";
import { useTour } from "hooks";
import { steps } from "./suppress-manually.tour";
import { ListsSelect } from "../lists-select/lists-select";

import styles from "./suppress-manually.module.scss";

interface Props {
  handleFileClick: () => void;
}

export const SuppressManually: React.FC<Props> = ({ handleFileClick }) => {
  useTour({
    name: "iterable-details",
    steps: steps({ fileModeClick: handleFileClick }),
  });

  const navigate = useNavigate();
  const textareaRef = useRef<(HTMLInputElement & HTMLTextAreaElement) | null>(null);

  const { user } = useSelector((state: RootState) => state.auth);

  const [textareaValue, setTextareaValue] = useState<string>("");
  const [selectedProjects, setSelectedProjects] = useState<number[]>([]);
  const [selectedDataExtensions, setSelectedDataExtensions] = useState<number[]>([]);

  const { submit, onSubmitSuccess, onSubmitError, submitting } = useSubmit(createSuppressions);
  onSubmitSuccess(() => {
    notification.success("Success!", "Emails were added to queue.");
    navigate(user?.isAdmin ? JOBS_LIST_PAGE.path : DASHBOARD_PAGE.path);
  });
  onSubmitError(() => {
    notification.error("Error!", "Could not add emails to queue.");
  });

  useDidUpdate(() => {
    if (textareaRef.current) {
      textareaRef.current.style.height = "100px";
      const scrollHeight = textareaRef.current.scrollHeight;

      textareaRef.current.style.height = `${scrollHeight}px`;
    }
  }, [textareaRef, textareaValue]);

  const handleSubmit = async (values: SuppressManuallyFormData) => {
    const emails = values.emails.split(/\r?\n/).filter(Boolean);
    await submit({ data: { values: emails, projectIds: selectedProjects, dataExtensionIds: selectedDataExtensions } });
  };

  return (
    <div className={styles.container}>
      <h2 className={styles.title}>Paste emails you want to add as suppression separated by a new line</h2>
      <Formik initialValues={initialValues} onSubmit={handleSubmit} validationSchema={validationSchema} validateOnMount>
        {({ isValid }) => (
          <Form>
            <FormField
              name="emails"
              type="textarea"
              label="Emails"
              forwardRef={textareaRef}
              onChange={setTextareaValue}
              inputClassName={styles.textarea}
              className="emails-textarea"
            />

            <ListsSelect
              selectedProjects={selectedProjects}
              setSelectedProjects={setSelectedProjects}
              selectedDataExtensions={selectedDataExtensions}
              setSelectedDataExtensions={setSelectedDataExtensions}
            />

            <div className={styles.buttons}>
              <Button type="submit" disabled={!isValid || selectedProjects.length === 0} loading={submitting}>
                Submit
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};
